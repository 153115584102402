body {
    background: #c0c0c0;
}

.enteteSemaine {
    background-color: #a5a5a5 !important;
    color: #000000 !important;
    text-align: center !important;
}

.totalSemaine {
    font-weight: bold !important;
    font-size: 24px !important;
}

.weekEndSemaine {
    background-color: #82D7FF !important;
}
